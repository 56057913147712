<template>
  <div class="popup">
    <div class="popup-inner rounded-3 border-popup-custom shadow" v-if="!displayProgressBarPopup">
      <h4 class="constituer-dossier-bold-content popup-title-up">IMPORTER UN FICHIER</h4>
      <span class="upload-popup-close-pos cursor-pointer" @click="closePopup()">
                <strong>X<i class="bi bi-x-lg"></i></strong>
            </span>
      <hr/>
      <div class="row cgu-sub-div" v-if="document">

        <div class="col-12 col-md-6 rounded-3 border-inner-popup-custom preview-upload text-start">
          <img class="w-100" :src="getDocumentImage()" v-bind:class="{ hide_element : !displaySpecimenSketches || this.startWithUploadDocument }"/>
          
          <div v-for="(item, index) in uploadedFiles" :key="index">
            <template v-if="item.type === 'image'">
              <img :src="item.file" class="w-100">
            </template>
            <template v-else-if="item.type === 'pdf'">
              <pdf :src="item.file" :page="1" :paginationEnabled="true"></pdf>
            </template>
          </div>

        </div>

        <div class="col-12 col-md-6 sm-upload-padding">
          <h4>{{ document.label }}</h4>
          <div class="px-3 py-2">
            <div class="py-1">
              <input type="file" v-for="(file, index) in upload_file_count" :key="file" :name="'file'+index"
                     :ref="'file'+index" class="form-control input-popup-upload-file shadow-none mb-2"
                     placeholder="Nom du document.jpg" aria-label="Username"
                     aria-describedby="basic-addon1" accept="image/png,image/jpeg,application/pdf">
              <span :class='[
                  "text-danger",
                  hasFile ? "hide_element" : ""
                ]'>{{ErrorNoFile_Label}}</span>
            </div>

            <div v-bind:class="{ hide_element : !isActive }">
              <div class="text-start px-3 py-2 cursor-pointer" @click="addUploadFileRow()">
                <img class="img-add-file" src="@/assets/images/plus_file.svg"/><span class="px-3 add-file-text-color "
                                                                                     > <u>Ajouter un fichier </u></span>
              </div>
              <div class="py-5">
                <button type="button" v-on:click="displayUploadConfirmationBlck()"
                        class="button-warning-cus">{{ Acceder_Label }}
                  <div class="arrow"/>
                </button>
              </div>
            </div>
          </div>
          <div v-bind:class="{ hide_element : isActive }">
            <div class="text-start">
              <span class="send-text-p">Une fois téléversé, vous ne pourrez plus modifier votre document sans l'aide de votre conseiller.</span>
            </div>
            <div class="py-3">
              <button type="button" v-on:click="handleFilesUpload()"
                      class="button-warning-cus">{{ Continuer_Label }}
                <div class="arrow"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayProgressBarPopup">
      <ProgressBar @showUploadDocumentPopup="closePopup" :percentage="percentCompleted" />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import pdf from 'pdfvuer'
import ProgressBar from "../../components/Popups/ProgressBar.vue"
import events from "../../constants/events"
import emitter from "../../events/emitter"
import Document from '../../models/document';
import { map } from '@/mapper/DocumentImageMapper';
import {base64toURL} from '../../utils/file.js';

export default {
  components: {
    ProgressBar,
    pdf
  },
  props: {
    document: Document,
    startWithUploadDocument: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Acceder_Label: "Continuer",
      Continuer_Label: "Continuer",
      Error_Upload_Label: "Une erreur est survenue lors du téléchargement de votre document",
      ErrorNoFile_Label: "Veuillez sélectionner un fichier",
      hasFile: true,
      upload_file_count: 3,
      isActive: true,
      displayProgressBarPopup: false,
      uploadedImgFiles: [],
      uploadedPdfFiles: [],
      uploadedFiles: [],
      displaySpecimenSketches: true,
      percentCompleted: 0,
      documentUploadedContent: null
    }
  },
  mounted() {
    if (this.document.file) {
      this.displayDocument();
    }
  },
  methods: {
    ...mapActions('document', ['uploadDocument', 'getDocumentContent']),
    onFileSelected(e) {
      const file = e.target.files[0];
      this.uploadedFiles.push(URL.createObjectURL(file));
      this.displaySpecimenSketches = false;
    },
    addUploadFileRow() {
      this.upload_file_count += 1;
    },
    displayUploadConfirmationBlck() {
      // checking
      if (!this.areFilesInputsEmpty()) {
        this.hasFile = true;
        this.isActive = !this.isActive;
      } else {
        this.hasFile = false;
      }
    },
    /**
     * @return bool
     */
    areFilesInputsEmpty() {
      var returnBool = true; // On retourne true si aucun des formulaire files sont rempli
      for (var i = 0; i < this.upload_file_count; i++) {
        const input = this.$refs['file' + i];
        if (input[0].files.length !== 0) {
          returnBool = false;
          this.previewUploadedFiles(i);
        }
      }
      return returnBool;
    },
    /**
     * @param int i index of file
     */
    previewUploadedFiles(i) {
      const input = this.$refs['file' + i];
      const file = input[0].files[0];

      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        this.uploadedImgFiles.push(URL.createObjectURL(file));
        this.totalNumberOfItems++;
        this.uploadedFiles.push({
          type: 'image',
          file: URL.createObjectURL(file)
        });
      }
      if (file.type === 'application/pdf') {
        this.uploadedPdfFiles.push(URL.createObjectURL(file));
        this.totalNumberOfItems++;
        this.uploadedFiles.push({
          type: 'pdf',
          file: URL.createObjectURL(file)
        })
      }

      this.displaySpecimenSketches = false;
    },
    /**
     *
     */
    handleFilesUpload() {
      const files = [];
      if (this.document.file) {
        // Suppose que vous avez votre chaîne base64 représentant le fichier PDF
        const base64String = "data:application/pdf;base64," + this.documentUploadedContent;
        const byteCharacters = atob(base64String.split(',')[1]); // Décodage de la chaîne base64

        // Convertir la chaîne en tableau d'octets
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Créer un objet Uint8Array à partir du tableau d'octets
        const byteArray = new Uint8Array(byteNumbers);

        // Créer un objet Blob à partir des données binaires
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const file = new File([blob], this.document.label + '.pdf', { type: 'application/pdf' });
        files.push(file);
      }
      for (var i = 0; i < this.upload_file_count; i++) {
        const input = this.$refs['file' + i];
        if (input[0].files.length !== 0) {
          files.push(input[0].files[0]);
        }
      }

      if(files.length != 0){
        this.submitFiles(files);
      }
    },
    /**
     * Reset upload vars
     */
    reset() {
      this.displayProgressBarPopup = false;
      this.displaySpecimenSketches = true;
      this.uploadedImgFiles = [];
      this.uploadedPdfFiles = [];
      this.hasFile = false;
      this.isActive = true;
    },
    /**
     * @param File[] files
     */
    submitFiles(files) {
      this.uploadDocument({
        idDocument: this.document.id,
        files,
        label: this.document.label,
        category: this.document.category,
        progressHandler: function(progressEvent) {
          this.percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        }.bind(this)
      }).then((response) => {
        if (response === "OK") {
          emitter.emit(events.notifications.refresh);
          this.closePopup(true);
        } else {
          this.uploadFailed(response);
        }
      }).catch((error) => {
        this.uploadFailed(error);
      }).finally(() => {
        this.percentCompleted = 0;
      });
      this.displayProgressBarPopup = true;
    },
    uploadFailed(error) {
      console.error('FAILURE!! error:', error);
      console.error('images:', this.uploadedImgFiles);
      console.error('pdfs:', this.uploadedPdfFiles);
      this.reset();
      alert(this.Error_Upload_Label);
    },
    /**
     * @param {Boolean} refresh If true: refresh Data
     */
    closePopup(refresh) {
      //this.$emit('showUploadDocumentPopup', false)
      emitter.emit("close-upload-popup", {refresh});
    },
    /**
     *
     * @returns {*} Image src required
     */
    getDocumentImage() {
      let image = map(this.document.label, this.document.date) || 'stickers-doc-upload';
      return require(`@/assets/images/placeholder-document/${image}.jpg`);
    },
    /**
     * @return the document already uploaded
     */
     displayDocument() {
      this.getDocumentContent(this.document.id).then((data) => {
        if (data) {
          this.documentUploadedContent = data;
          this.uploadedPdfFiles.push(base64toURL(data, 'application/pdf'));
          this.totalNumberOfItems++;
          this.uploadedFiles.push({
            type: 'pdf',
            file: base64toURL(data, 'application/pdf')
          });
        }
      }).finally(() => {
        this.dataLoadingMask = 0;
      });
      this.dataLoadingMask = 1;
    }
  }
}
</script>

<style scoped>
.preview-upload {
  display: none;
}

.popup {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);
}

.popup-inner {
  background: #FFF;
  padding: 32px;
  width: 100%;
}

.sm-upload-padding {
  margin-left: 20px;
}

.popup {
  position: fixed;
  width: auto;
  display: flex;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background: #FFF;
  padding: 32px;
  width: 90%;
}

.sm-upload-padding {
  margin-left: 0px;
}

@media screen and (min-width: 768px) {
  .preview-upload {
    display: block;
  }
  .popup-inner {
    width: 800px;
  }

  .cgu-sub-div {
    width: 700px;
  }
}

.popup-title-up {
  display: inline-block;
}

.border-popup-custom {
  border-color: #E0E8FD !important;
  border: solid;
  border-width: 3px;
}

.border-inner-popup-custom {
  border-color: #E7E7E7 !important;
  border: solid;
  border-width: 2px;
  height: auto;
}

.cgu-sub-div {
  margin: 0 auto;
}

.img-add-file {
  width: 12%
}

.img-remove-file {
  width: 20px
}

.add-file-text-color {
  color: #454895;
}

.send-text-p {
  color: #111179;
}

.input-popup-upload-file {
  background-color: #F2F2F2;
  border-radius: 15px;
  border: 0;
  width: 90%;
}

.input-remove-uploaded-file {
  border: 0;
  width: 30px;
  background: #F2F2F2;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.upload-popup-close-pos {
  float: right;
  color: #111179;
}

</style>
